<template>
  <base-outlined-button
    :text="text"
    :color="color"
    :icon="icon"
    :disabled="isDisabled"
    :loading="loading"
    :toolbar="toolbar"
    :small="small"
    @onButtonClick="onButtonClick"
  />
</template>

<script>
  import ButtonMixin from '../mixins/Button';

  export default {
    name: "ConfirmButton",
    mixins: [ButtonMixin]
  }
</script>
